import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import Helmet from "../../components/helmet/helmet";
import MSmodal from "../../components/modal/MSmodal";
import NavBar from "../../components/navbar/navbar";
import FullimageComponent from "./FullImageComponent";
import ImageComponent from "./ImageComponent";
import "./index.scss"


const ImagesPage = (props) => {

    const { location } = props;

    const [images,setImages] = useState([])

    

    useEffect(()=>{
      const cache = {};

      function importAll(r) {
          r.keys().forEach((key) => (cache[key] = r(key)));
      }

      importAll(require.context("../../images/imagesList", false, /\.(png|jpe?g|svg)$/));
  
      setImages(Object.entries(cache).map(module => module[1].default));
    },[])

    const [image, setImage] = useState(null);

    const goNextPict = () =>{
      const index = images.indexOf(image)
      if(index >= images.length-1){
        setImage(images[0])
        return;
      }
      setImage(images[(index+1)])

    }

    const goBackPict = () =>{
        const index = images.indexOf(image)
        if(index <= 0){
          setImage(images[(images.length-1)])
          return;
        }
        setImage(images[(index-1)])
  
      }


    return (<div className="MainContainer Images">
        <Helmet />
        <NavBar location={location} />
        <div className="Content imageList">
            {images.map((el, idx) => <ImageComponent onClick={() => setImage(el)} key={`${el}-${idx}`} src={el} />
            )}
        </div>
        <MSmodal className="ModalImage" fullscreen show={Boolean(image)} onHide={() => setImage(null)} >
            <MSmodal.Body>
                {Boolean(image) && <FullimageComponent src={image} onChangeForward={goNextPict} onChangeBack={goBackPict} />}
            </MSmodal.Body>
        </MSmodal>
        <Footer />
    </div>)
}

export default ImagesPage;
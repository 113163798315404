import React from 'react';
import { Modal } from 'react-bootstrap';

const MSmodal = (props) => {
    const { header, children, footer } = props;
    return <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            {header}
        </Modal.Header>
        <Modal.Body>
            {children}
        </Modal.Body>
        {footer && <Modal.Footer>
            {footer}
        </Modal.Footer>
        }
    </Modal>
}

MSmodal.Body = (props) => {
    const { children } = props;
    return <>{children}</>
}

MSmodal.Footer = (props) => {
    const { children } = props;
    return <div>
        {children}
    </div>
}

MSmodal.Header = (props) => {
    const { children } = props;
    return <Modal.Title id="contained-modal-title-vcenter">
        {children}
    </Modal.Title>
}

export default MSmodal;